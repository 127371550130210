<script setup lang="ts">
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { isEmpty } from 'lodash-es';
import { storeToRefs } from 'pinia';
import {
  useHotelRecommendation,
  useHotelPath,
  useIntersectionObserver,
  until,
  ref,
  useNuxtApp,
  computed,
} from '#imports';

import 'swiper/css';
import 'swiper/css/navigation';

const modules = [Navigation];

const { $device } = useNuxtApp();
const { country, currency } = storeToRefs(useConfigStore());
const { locale } = useI18n();
const { getHotelPath } = useHotelPath();
const {
  activeCityRef,
  changeActiveCity,
  trackPopularHotel,
  trackPopularHotelNav,
  trackPopularHotelSlide,
  getHotelSearchPath,
  trackPopularHotelSeeMore,
} = useHotelRecommendation();

const isBot = $device.isCrawler;

const {
  data: cities,
  error: cityError,
  pending: cityPending,
  execute: fetchHotelRecommendationCities,
} = await useAsyncData(
  () =>
    useHotelService().getHotelRecommendationCities({
      country: country.value!,
      lang: locale.value,
    }),
  {
    immediate: isBot,
    transform: (data) => data.result.cities,
  },
);

const activeCity = computed(() => activeCityRef.value || (cities.value && cities.value[0]!));

const { data: hotels, pending: hotelPending } = await useAsyncData(
  () =>
    useHotelService().getHotelRecommendationsByCity({
      city: activeCity.value.id,
      currency: currency.value!,
      lang: locale.value,
    }),
  {
    immediate: isBot,
    watch: [currency, activeCityRef, cities],
    transform: (data) => data.result.hotels,
  },
);

const hotelRecommendationRef = ref<HTMLElement | null>(null);
const initialState = ref(!isBot);

const { stop } = useIntersectionObserver(
  hotelRecommendationRef,
  // @ts-expect-error
  async ([{ isIntersecting }]) => {
    if (isIntersecting && !isBot) {
      fetchHotelRecommendationCities();

      await until(cityPending).toBe(false);

      initialState.value = false;

      stop();
    }
  },
);
</script>

<template>
  <div ref="hotelRecommendationRef">
    <h2 class="text-extra font-bold mb-20">
      {{ $t('hotel.hotelrecommendation') }}
    </h2>

    <HotelRecommendationCityPlaceholder
      v-if="cityPending || initialState"
      class="mb-20"
    />

    <div
      v-if="!cityError && !cityPending && !isEmpty(cities)"
      class="flex gap-x-15 mb-20"
    >
      <div
        v-for="(city, index) in cities"
        :key="index"
        :class="{ '!bg-primary !border-white': city.id === activeCity.id }"
        class="py-10 px-20 border-[1px] rounded border-solid border-gray-light bg-whiter cursor-pointer hover:bg-primary hover:border-white hover:!text-white"
        data-testid="hotel-filter-button"
        @click="changeActiveCity(city)"
      >
        <p :class="{ '!text-white font-bold': city.id === activeCity.id }">
          {{ city.name }}
        </p>
      </div>
    </div>

    <HotelRecommendationPlaceholder
      v-if="hotelPending || cityPending || initialState"
      class="mb-[150px]"
    />

    <div
      v-else-if="!isEmpty(hotels)"
      class="w-full relative"
    >
      <Swiper
        :slides-per-view="4"
        :space-between="10"
        :lazy="!isBot"
        loop
        :navigation="{
          nextEl: '.hotel-recommendation-button-next',
          prevEl: '.hotel-recommendation-button-prev',
        }"
        :modules="modules"
        class="swiper w-full rounded"
        @touch-end="trackPopularHotelSlide(activeCity.name)"
      >
        <SwiperSlide
          v-for="(hotel, index) in hotels"
          :key="index"
          class="!w-[267px] mr-10 last:mr-0"
          @click="trackPopularHotel(hotel, activeCity)"
        >
          <NuxtLink
            :to="
              getHotelPath(hotel, {
                ci: hotel.checkInDate,
                co: hotel.checkOutDate,
                ro: '1',
                ad: '1',
              })
            "
            external
            no-rel
          >
            <div class="w-full relative shadow-md hover:cursor-pointer h-[235px]">
              <div
                class="absolute top-0 right-0 bottom-auto left-auto leading-3 bg-primary text-small flex justify-center items-center p-10 z-[1] rounded-tr rounded-bl"
              >
                <p class="text-small text-white font-bold">{{ hotel.rating }}/10</p>
              </div>
              <NuxtImg
                :src="hotel.photo"
                :alt="hotel.name"
                format="webp"
                height="235"
                width="267"
                class="rounded h-full w-full aspect-square object-cover"
                data-testid="hotel-recommended-card"
                :loading="index > 3 ? 'lazy' : 'eager'"
                fit="scale-down"
                sizes="lg:50vw"
                quality="50"
              />
            </div>
            <div
              class="flex flex-col items-start justify-start pt-10"
              data-testid="hotel-recommended-details"
            >
              <p class="font-bold mb-10 text-left text-gray-darkest">
                {{ hotel.name }}
              </p>

              <SharedRating
                :rating="hotel.star"
                size="large"
                class="mb-10"
                wrapper-class="flex gap-x-5"
              />

              <div class="flex flex-wrap items-end">
                <p class="mr-5">
                  {{ $t('search.from') }}
                </p>
                <p class="text-medium font-bold">
                  {{ $c(hotel.price, hotel.currency) }}
                </p>
              </div>
            </div>
          </NuxtLink>
          <div
            v-if="!isBot"
            class="swiper-lazy-preloader"
          ></div>
        </SwiperSlide>
      </Swiper>

      <ApzButton
        class="swiper-button-prev hotel-recommendation-button-prev hover:!bg-gray-lightest"
        @click="trackPopularHotelNav(activeCity.name, 'left')"
      />

      <ApzButton
        class="swiper-button-next hotel-recommendation-button-next hover:!bg-gray-lightest"
        @click="trackPopularHotelNav(activeCity.name, 'right')"
      />
    </div>

    <div class="mt-40 w-full flex justify-center min-w-[55px]">
      <ApzButton
        v-if="activeCity"
        type="primary"
        variant="outline"
        tag="nuxt-link"
        :to="getHotelSearchPath(activeCity)"
        external
        no-rel
        class="w-fit !px-30 !h-[55px] text-medium"
        @click="trackPopularHotelSeeMore(activeCity)"
      >
        {{
          $t('hotel.discovermore', {
            city_name: activeCity?.name ?? '',
          })
        }}
      </ApzButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.swiper-button-next,
.swiper-button-prev {
  @apply rounded-full text-gray-darkest bg-white top-[calc(235px/2-15px)] my-auto shadow-md;

  height: 48px;
  width: 48px;

  &::after {
    @apply font-bold;

    font-size: 18px;
  }
}

.swiper-button-prev {
  @apply -left-[24px];
}

.swiper-button-next {
  @apply -right-[24px];
}

.swiper-lazy-preloader {
  @apply border-x-primary border-b-primary;
}
</style>
